
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
    ADMIN_ADEPT_DATA,
    ADMIN_TRAINING_TARGETS_CREATE,
    ADMIN_TRAINING_TARGET_CATEGORIES_CREATE,
    ADMIN_ADEPT_TESTS_CREATE,
    ADMIN_MEASUREMENTS_CREATE,
    ADMIN_USER_EFFECT_CREATE,
    ADMIN_PREFERENCES_SURVEY_CREATE,
} from 'Consts/routes';

import { withVariables } from 'Utils/string';
import { parseQueryToObject } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import PageContent from 'Components/layout/panel/PageContent';
import TrainingTargetsList from 'Components/admin/trainingTargets/List';
import TrainingTargetCategoriesList from 'Components/admin/trainingTargetCategories/List';
import AdeptTestsList from 'Components/admin/adeptTests/List';
import MeasurementsList from 'Components/admin/measurements/List';
import MedicalList from 'Components/admin/medical/List';
import UserEffectsList from 'Components/admin/userEffects/List';
import UserSurveysList from 'Components/admin/userSurveys/List';

export const TAB_TRAINING_TARGETS = 'trainingTargets';
export const TAB_TRAINING_TARGET_CATEGORIES = 'trainingTargetCategories';
export const TAB_ADEPT_TESTS = 'adeptTests';
export const TAB_MEASUREMENTS = 'measurements';
export const TAB_MEDICAL_CARDS = 'medicalCards';
export const TAB_USER_EFFECTS = 'userEffects';
export const TAB_PREFERENCES_SURVEYS = 'preferencesSurveys';

export default class AdminAdeptData extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    defaultQuery = {
        tab: null,
    };

    render() {
        const { location, history } = this.props;
        const { search } = location;
        const queryObject = parseQueryToObject(search);

        return (
            <StyledComponent
                className="page-adept-data"
                styles={require('./styles')}
            >
                <PageContent
                    breadcrumbs={[{
                        to: withVariables(ADMIN_ADEPT_DATA.path),
                        label: 'Kartoteka',
                    }]}
                >
                    <TabNavigation
                        location={location}
                        history={history}
                        headline="Oferty"
                        controls={[{
                            visible: Boolean(!queryObject.tab || queryObject.tab === TAB_TRAINING_TARGETS),
                            key: 'trainingTargetCreate',
                            label: 'Nowy cel treningowy',
                            onClick: () => history.push(ADMIN_TRAINING_TARGETS_CREATE.path),
                        }, {
                            visible: Boolean(queryObject.tab === TAB_TRAINING_TARGET_CATEGORIES),
                            key: 'trainingTargetCategoryCreate',
                            label: 'Nowa kategoria celów treningowych',
                            onClick: () => history.push(ADMIN_TRAINING_TARGET_CATEGORIES_CREATE.path),
                        }, {
                            visible: Boolean(queryObject.tab === TAB_ADEPT_TESTS),
                            key: 'adeptTestCreate',
                            label: 'Nowy test',
                            onClick: () => history.push(ADMIN_ADEPT_TESTS_CREATE.path),
                        }, {
                            visible: Boolean(queryObject.tab === TAB_MEASUREMENTS),
                            key: 'measurementCreate',
                            label: 'Nowy pomiar',
                            onClick: () => history.push(ADMIN_MEASUREMENTS_CREATE.path),
                        }, {
                            visible: Boolean(queryObject.tab === TAB_USER_EFFECTS),
                            key: 'userEffectCreate',
                            label: 'Nowy efekt',
                            onClick: () => history.push(ADMIN_USER_EFFECT_CREATE.path),
                        }, {
                            visible: Boolean(queryObject.tab === TAB_PREFERENCES_SURVEYS),
                            key: 'preferencesSurveyCreate',
                            label: 'Nowa ankieta preferencji',
                            onClick: () => history.push(ADMIN_PREFERENCES_SURVEY_CREATE.path),
                        }]}
                        tabs={[{
                            key: TAB_TRAINING_TARGETS,
                            label: 'Cele treningowe',
                            children: (
                                <TrainingTargetsList
                                    location={location}
                                    history={history}
                                />
                            ),
                        }, {
                            key: TAB_TRAINING_TARGET_CATEGORIES,
                            label: 'Kategorie celów treningowych',
                            children: (
                                <TrainingTargetCategoriesList
                                    location={location}
                                    history={history}
                                />
                            ),
                        }, {
                            key: TAB_ADEPT_TESTS,
                            label: 'Testy',
                            visible: false,
                            children: (
                                <AdeptTestsList
                                    location={location}
                                    history={history}
                                />
                            ),
                        }, {
                            key: TAB_MEASUREMENTS,
                            label: 'Pomiary',
                            children: (
                                <MeasurementsList
                                    location={location}
                                    history={history}
                                />
                            ),
                        }, {
                            key: TAB_MEDICAL_CARDS,
                            label: 'Wywiady medyczne',
                            children: (
                                <MedicalList
                                    location={location}
                                    history={history}
                                />
                            ),
                        }, {
                            key: TAB_USER_EFFECTS,
                            label: 'Efekty',
                            children: (
                                <UserEffectsList
                                    location={location}
                                    history={history}
                                />
                            ),
                        }, {
                            key: TAB_PREFERENCES_SURVEYS,
                            label: 'Ankiety preferencji',
                            children: (
                                <UserSurveysList
                                    location={location}
                                    history={history}
                                />
                            )
                        }]}
                    />
                </PageContent>
            </StyledComponent>
        );
    }
}
